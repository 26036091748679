import React from 'react';
import { number } from 'prop-types';
import SkeletonCard from 'components/cards/skeleton';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const SkeletonLoyaltyCardsView = ({ passCount = 1 }) => {
  const styles = useStyles();

  const fakePasses = [];
  for (let i = 0; i < passCount; i += 1) {
    fakePasses.push(<SkeletonCard/>);
  }

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <Skeleton animation="wave" height={50} className={styles.bodyHeader}/>
        <Skeleton animation="wave" height={25} className={styles.body2Indent}/>
        <div className={styles.content}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            {fakePasses.map((fake, index) => (
              <Grid item md={4} sm={6} xs={12} key={index}>
              {fake}
              </Grid>
            ))}
          </Grid>
        </div>
        <SkeletonFooter/>
        </>
  );
};

SkeletonLoyaltyCardsView.propTypes = { passCount: number };

export default SkeletonLoyaltyCardsView;
