import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import queryString from 'query-string';
import loadable from '@loadable/component';

import useCanAddPass from 'hooks/use-can-add-pass';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import SkeletonLoyaltyCardsView from 'views/loyalty-cards/skeleton';
import { useNavigationContext } from 'contexts/navigation-context';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

const LazyLoyaltyCardsPage = loadable(() => import('suspended-page/loyalty-cards'));

const LoyaltyCardsPage = ({ location = {} }) => {
  const logger = buildLogger();
  const { search = '' } = location;
  const { bounced = false } = queryString.parse(search);

  const [loading, setLoading] = useState(true);
  const [passes, setPasses] = useState([]);
  const {
    canAdd,
    hasSub,
    authenticated,
    canAddLoading,
  } = useCanAddPass();

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();
  const { showNotification, types } = noticationContext;
  const { homePageOnly = () => {} } = useNavigationContext();

  useEffect(() => {
    const fetchPassData = async () => {
      try {
        const getPassesService = await import('services/passes/get-passes-service');
        const { getPasses } = getPassesService.default({
          stateContext,
          noticationContext,
        });

        const oncionPasses = await getPasses();
        setPasses(oncionPasses);
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'fetchPassData:onError' },
          message,
          trace,
        }));
      } finally {
        setLoading(false);
      }
    };
    if (bounced) {
      setTimeout(() => showNotification({
        message: 'Something went wrong. You have been redirected to "your cards".',
        type: types.warning,
        duration: 4000,
      }), 1000);
    }

    if (!canAddLoading) {
      fetchPassData();
    }

    // eslint-disable-next-line
  }, [canAddLoading]);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (loading) {
    return <SkeletonLoyaltyCardsView passCount={
      Array.isArray(passes) && passes.length !== 0 ? passes.length : 1
    }/>;
  }

  return (<LazyLoyaltyCardsPage passes={passes} canAdd={canAdd} hasSub={hasSub}/>);
};

LoyaltyCardsPage.propTypes = {
  location: object,
};

export default LoyaltyCardsPage;
